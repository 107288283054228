import React from 'react';

import Layout from '@layouts/Layout';

import BespokeCallToAction from '@components/call-to-actions/BespokeCallToAction';
import ModularCallToAction from '@components/call-to-actions/ModularCallToAction';
import ContactCallout from '@components/callouts/ContactCallout';

const FourOhFour = () => (
    <Layout metaData={{ title: 'Page not found' }}>
        <header className="hero background-color--chalk">
            <div className="container text-align--center">
                <h1 className="text-page-title four-oh-four__title island--xlarge">Page not found</h1>
                <span className="text-paragraph">Whoops! The page you were looking for doesn’t exist, or has been moved.</span>
            </div>
        </header>
        <section className="container backdrop backdrop--top-half">
            <div className="grid-x grid-margin-x grid-margin-y">
                <div className="cell medium-6">
                    <ModularCallToAction />
                </div>
                <div className="cell medium-6">
                    <BespokeCallToAction />
                </div>
            </div>
        </section>
        <section className="wrapper--medium container">
            <ContactCallout />
        </section>
    </Layout>
);

export default FourOhFour;
